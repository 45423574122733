import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Row, { Col } from '../../Elements/Grid';
import PageSection from '../../Elements/PageSection';
import JobList from '../../Pages/Vacatures/JobList';
import Image from '../../Elements/Image';
import LinkButton from '../../Elements/LinkButton';

const Part = ({ title, showList, image }) => {
  const { t } = useTranslation('componentGetAJob');

  return (
    <PageSection image className="jobs">
      <Row>
        <Col filled>
          <Image
            image={image}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </Col>
        <Col>
          <h2>{title || t('defaultTitle')}</h2>
          <p>{t('description.p1')}</p>
          <p>{t('description.p2')}</p>
          {showList ? (
            <>
              <p>{t('vacancies')}</p>
              <JobList />
            </>
          ) : (
            <LinkButton to="/contact">{t('callToAction')}</LinkButton>
          )}
        </Col>
      </Row>
    </PageSection>
  );
};

Part.defaultProps = {
  title: false,
  showList: true,
  image: 'footerJoinUs',
};

Part.propTypes = {
  title: PropTypes.string,
  showList: PropTypes.bool,
  image: PropTypes.string,
};

export default Part;
