import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../components/Layout';
import Cover from '../../components/Elements/Cover';
import PageSection from '../../components/Elements/PageSection';
import { GetJobs } from '../../components/Pages/Vacatures/JobList';
import Row, { Col } from '../../components/Elements/Grid';
import GetAJob from '../../components/Parts/GetAJob';
import Image from '../../components/Elements/Image';
import LinkButton from '../../components/Elements/LinkButton';
import withTranslations from '../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('vacatures');

  return (
    <Layout className="vacatures">
      <Seo title={t('seoTitle')} />
      <Cover image="avocado" nowrap>
        <Image
          image="headerVacatures"
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center center' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{t('intro.title')}</h1>
            <p>{t('intro.description.p1')}</p>
            <p>{t('intro.description.p2')}</p>
            <p>{t('intro.description.p3')}</p>
            <p>{t('intro.description.p4')}</p>
          </PageSection>
          <GetJobs
            render={({ jobs }) => (
              <PageSection slide center className="vacature-list">
                <Row>
                  {jobs.entries.map((job) => (
                    <Col key={job.link}>
                      <h3>{job.title}</h3>
                      <div className="description">
                        <p>{job.description}</p>
                      </div>
                      <div className="vacature-footer">
                        <LinkButton to={`/vacatures/${job.link}`}>{t('viewVacancy')}</LinkButton>
                      </div>
                    </Col>
                  ))}
                </Row>
              </PageSection>
            )}
          />
          <GetAJob title={t('getAJobTitle')} showList={false} image="footerVacatures" />
        </div>
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
